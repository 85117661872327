@use "../../imports";

.about {
    .image-caption-group {
        margin: 1rem auto;
        width: 100%;

        span {
            font-size: 0.75rem;
            font-style: italic;
            color: imports.$ter-black;
            text-align: center;
            display: block;
            text-align: center;
        }

        img {
            width: 100%;
            display: block;
            margin: auto;
        }

        .image-grid {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: column;
            width: 100%;

            .grid-item {
                margin: 0.5rem;
                flex: 1;
                max-width: 268px;

                img {
                    width: 100%;
                }
            }
        }
    }

    .flex-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .text,
        .image {
            margin: 0.5rem;
        }

        .text {
            flex: 1;
        }

        .image {
            flex: 0;
        }

        .icon-container {
            flex: 1;
            text-align: center;
            margin: 1rem auto;
            .icon {
                width: 4rem;
                height: 4rem;
                color: imports.$dark-gold;
            }
            .title {
                font-weight: 600;
                color: imports.$sec-black;
                margin: 0.25rem auto;
            }
            .label {
                font-size: 0.75rem;
            }
        }
    }

    .meditation,
    .help {
        flex-direction: column-reverse;
    }
    .dharma,
    .donations,
    .biography {
        text-align: center;
    }

    &.gold {
        .image-caption-group {
            span {
                color: imports.$ter-white;
            }
        }
        .flex-container {
            .icon-container {
                .icon {
                    width: 5rem;
                    height: 5rem;
                    margin: 1.25rem;
                    color: imports.$sec-white;
                }
                .title {
                    color: imports.$sec-white;
                }
                .label {
                    color: imports.$ter-white;
                    line-height: 150%;
                }
            }
        }
    }

    .expandable {
        margin: auto;

        .button {
            color: imports.$light-red;
            cursor: pointer;
            transition: color 0.25s ease;
            &:hover {
                color: imports.$not-as-light-red;
            }
            &:active {
                color: imports.$dark-red;
            }
        }

        .content {
            width: 85%;
            margin: 0.5rem auto;
            overflow: hidden;
            transition: max-height 1s ease;
            max-height: 0;

            &.expanded {
                max-height: 2000px;
            }
        }
    }

    .zelle {
        width: 90%;
        max-height: 600px;
        margin: 0.5rem;
    }
}

@media screen and (min-width: 768px) {
    .about {
        .image-caption-group {
            .image-grid {
                flex-direction: row;
            }

            img[alt="PMC Compound Building."] {
                max-width: 500px;
            }

            img[alt="Meditation Session"] {
                width: 280px;
            }

            img[alt="H.E.L.P. Program"] {
                width: 240px;
            }

            img[alt="Founder Pana Dipa Bhivasama"] {
                width: 300px;
            }
        }

        .flex-container {
            flex-direction: row;
            .text,
            .image {
                margin: 1rem;
            }
        }

        .meditation {
            flex-direction: row;
            text-align: right;
        }
    }
}
