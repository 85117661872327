@use "../../imports.scss";

.booklets {
    .grid-wrap {
        display: grid;
        width: 90%;
        margin: auto;
        grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
        gap: 1rem;
    }

    a.booklet {
        display: block;
        text-decoration: none;
        color: imports.$ter-black;
        &:hover {
            color: imports.$light-red;
        }
        &:active {
            color: imports.$not-as-light-red;
            background: imports.$ter-white;
        }
    }

    .booklet {
        background: imports.$sec-white;
        text-align: center;
        padding: 1rem 0 2rem;
        margin: 0.5rem auto;
        cursor: pointer;

        .name {
            padding: 0.5rem;
            text-transform: uppercase;
            letter-spacing: 0.0625rem;
            font-weight: 600;
        }

        .date {
            margin-bottom: 0.5rem;
        }
    }
}

@media screen and (min-width: 768px) {
    .booklets {
        .grid-wrap {
            width: 100%;
        }
    }
}
