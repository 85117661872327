@use "../../imports";

a.event {
    color: imports.$sec-white;

    .desc .title {
        text-decoration: underline;
    }

    &:hover,
    &:active {
        color: imports.$sec-white;
        background: imports.$dark-gold;
    }
}

.event {
    color: imports.$sec-white;
    display: block;
    padding: 0.5rem 0;

    .desc {
        .title {
            font-weight: 600;
            font-size: 1.5rem;
            letter-spacing: 0.0625rem;
            text-transform: capitalize;
        }
        .location {
            font-size: 0.875rem;
            color: imports.$ter-white;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            .icon {
                width: 1.25rem;
                height: 1.25rem;
                color: imports.$sec-white;
                margin: 0.25rem 0.5rem 0.25rem 0;
                flex: 0;
            }

            .lines {
                flex: 1;
                margin: 0.25rem 0;
            }
        }
    }

    .date {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin: 0.75rem auto 0;
        font-size: 0.875rem;
        color: imports.$ter-white;
    }
}

.event-list {
    margin: 1rem auto 1rem;
    color: imports.$ter-white;
}

.no-events {
    color: imports.$ter-white;
    font-weight: 700;
    line-height: 300%;
}

@media screen and (min-width: 768px) {
    .event {
        display: flex;
        align-items: center;
        padding: 0.5rem;
        transition: background 0.25s ease;
        .desc {
            flex: 3;
            text-align: left;
            .title {
                font-size: 2rem;
            }
            .location {
                margin-top: 0.25rem;
            }
        }
        .date {
            flex: 1;
            display: block;
            margin: 0;
            .day {
                text-align: right;
                font-size: 1.25rem;
                margin-bottom: 0.75rem;
            }
            .time {
                margin-top: 0.75rem;
                text-align: right;
            }
        }
    }
}
