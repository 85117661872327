@use "../../imports";

.contact {
    text-align: center;

    h6 {
        margin: 1rem auto 2rem;
    }

    .contact-info {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        text-align: left;

        .contact-info-card {
            display: flex;
            align-items: center;
            margin: 2rem auto;
            width: 100%;

            svg.icon {
                flex: 0 1 4rem;
                width: 4rem;
                height: 4rem;
                background: imports.$light-gold;
                border-radius: 50%;
                padding: 1rem;
                color: imports.$prim-white;
            }

            .desc {
                margin-left: 1rem;

                .title {
                    color: imports.$prim-black;
                    text-transform: uppercase;
                    letter-spacing: 0.0625rem;
                    font-weight: 700;
                    margin: 0 0 0.375rem;
                    font-size: 0.75rem;
                }

                .info {
                    font-size: 1rem;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .contact {
        .content-wrap {
            display: flex;
            align-items: center;
        }

        .contact-info {
            grid-template-columns: repeat(2, 1fr);
        }

        .contact-info,
        .contact-form-container {
            flex: 1;
        }
    }
}
