@use "../../imports";

.post {
    @keyframes postfadein {
        from {
            opacity: 0;
            transform: scale(0.98);
        }
        to {
            opacity: 1;
            transform: scale(1);
        }
    }

    width: 100%;
    margin: 1rem auto;
    background: imports.$sec-white;
    padding: 1rem;
    animation: 0.5s ease-in postfadein;

    .post__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .header__text {
            flex: 1;
            margin-bottom: 0.5rem;

            .label {
                text-transform: uppercase;
                color: imports.$sec-black;
                letter-spacing: 0.0625rem;
                font-weight: 600;
                font-size: 1.125rem;
                margin: 0 0 0.25rem;
            }

            .title,
            .title a {
                text-transform: capitalize;
                color: imports.$prim-black;
                letter-spacing: 0.0625rem;
                font-weight: 400;
                font-size: 2rem;
                margin: 0.5rem auto;
            }

            .title a {
                text-decoration: underline;
                &:hover {
                    color: imports.$light-red;
                }
            }

            .post-info {
                color: imports.$not-as-light-gold;

                strong {
                    color: imports.$dark-gold;
                }

                .times {
                    font-size: 1rem;
                    margin: 0.25rem auto;

                    .start,
                    .end {
                        margin: 0.125rem 0;
                    }
                }

                .location {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-start;
                    margin: 0.25rem auto;

                    .icon {
                        width: 1.5rem;
                        height: 1.5rem;
                        color: imports.$light-gold;
                        flex: 0;
                    }

                    .address {
                        flex: 1;
                        font-size: 0.75rem;
                        font-weight: 600;
                        color: imports.$light-gold;
                        margin-left: 0.5rem;
                        letter-spacing: 0.0625rem;
                    }
                }
            }
        }

        .header__operations {
            flex: 0;

            button {
                @include imports.styled-button("black");
                white-space: nowrap;
                font-size: 0.75rem;
            }
        }
    }

    img {
        max-width: 90%;
        // margin: 1em auto;
        display: block;
    }

    .media {
        img {
            width: 60%;
            margin: 1rem auto;
            border: solid 1px imports.$light-gold;
        }

        .pdfContainer {
            width: 90%;
            max-height: 500px;
            margin: 1rem auto;
            border: solid 1px imports.$light-gold;
            overflow-y: scroll;
        }
    }
}

@media screen and (min-width: 768px) {
    .post {
        padding: 2rem;

        .post__header {
            flex-direction: row;

            .post__text {
                margin-bottom: 0;
            }
        }
    }
}
