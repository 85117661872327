@use "../../imports.scss";

.shareButton {
    white-space: nowrap;
    font-size: 0.75rem;

    &.black {
        @include imports.styled-button("black");
    }

    &.white {
        @include imports.styled-button("white");
    }

    &.gold {
        @include imports.styled-button("gold");
    }
}
