@use "../../imports.scss";

a.link-button {
    @include imports.styled-button;
}

a.btn-white {
    @include imports.styled-button("white");
}

a.btn-black {
    @include imports.styled-button("black");
}
