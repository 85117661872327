@use "../../imports";

.home-landing {
    padding: 3rem 0 0;
    width: 100%;
    height: 400px;
    background: center/cover url("../../images/landing.jpg");
    text-align: center;

    .logo {
        margin: 1rem auto 0;
        width: 90%;
        max-width: 25rem;
        text-align: center;

        img {
            width: 8rem;
        }

        .title {
            font-weight: 700;
            color: imports.$prim-white;
            font-size: 1.75rem;
            margin: 0.5rem 0;
        }
    }

    .divider {
        display: block;
        height: 0.25rem;
        background: imports.$prim-white;
        width: 10rem;
        margin: auto;
    }

    .tagline {
        color: imports.$sec-white;
        margin: 1rem auto 0;
        width: 90%;
    }
}

.flex-wrapper {
    width: 100%;
}

.img-flexwrap,
.text-desc {
    width: 90%;
    max-width: 25rem;
    margin: 1rem auto;
}

.img-flexwrap {
    .help-img,
    .about-img {
        width: 100%;
    }
}

.text-desc {
    text-align: center;
}

.updates {
    text-align: center;
}

@media screen and (min-width: 768px) {
    .home-landing {
        .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 42rem;
            max-width: none;
            margin: 2rem auto 1rem;

            .about-img {
                width: 11rem;
                padding: 1rem;
                flex: 0;
            }

            .title {
                flex: 1;
                vertical-align: middle;
                text-align: left;
                font-size: 3rem;
                margin: 0 0 0 0.5rem;
            }
        }

        .divider {
            margin: 1rem auto;
        }

        .tagline {
            width: 768px;
        }
    }

    .flex-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .img-flexwrap {
        flex: 0;
    }

    .text-desc {
        flex: 1;
    }

    .img-flexwrap {
        img {
            margin: 0 1rem;
        }

        .about-img {
            width: 22rem;
        }

        .help-img {
            width: 20rem;
        }
    }

    .about-desc {
        text-align: left;
    }
    .help-desc {
        text-align: right;
    }
}
