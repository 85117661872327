@use "../../imports.scss";

/* Every style rule formatting the mobile and desktop navbar. */
.nav {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    height: 3rem;
    transition: background 0.25s ease;

    .hamburger-con {
        width: 2.5rem;
        display: inline-block;
        margin: 0.875rem 0.5rem;
        position: relative;
        z-index: 102;
        text-align: center;
    }

    ul {
        padding: 3rem 0 0;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        height: 100%;
        width: 15rem;
        background: imports.$dark-gold;
        z-index: 101;
        transform: translateX(-100%);
        transition: transform 0.25s ease;
        list-style: none;

        li {
            text-align: center;
            padding: 1.625rem 0;
        }

        li:nth-child(odd) {
            background: imports.$even-darker-gold;
        }

        a {
            text-decoration: none;
            color: imports.$sec-white;
            text-transform: uppercase;
            font-size: 0.75rem;
            letter-spacing: 0.1rem;
        }
    }

    ul.expanded {
        transform: translateX(0);
        transition: transform 0.25s ease;
    }
}

.scrolling {
    background: imports.$prim-white;
}

@media screen and (min-width: 768px) {
    .nav {
        .hamburger-con {
            display: none;
        }

        ul {
            position: relative;
            margin: 0.875rem 0;
            padding: 0;
            height: auto;
            transform: none;
            background: none;
            width: auto;
            text-align: center;

            li {
                padding: 0 1rem;
                display: inline-block;
                background: none;
            }

            li:nth-child(odd) {
                background: none;
            }

            a {
                color: imports.$sec-white;
                text-transform: capitalize;
                font-size: 1rem;
                transition: color 0.25s ease;
                &:hover {
                    color: imports.$light-red;
                }
                &.active {
                    font-weight: 700;
                }
            }
        }
    }

    .scrolling {
        ul a {
            color: imports.$sec-black;
        }
    }
}
