@use "../../imports";

.hamburger-button {
    width: 1.75rem;
    transition: width 0.25s ease;
    cursor: pointer;
    margin: auto;

    .bar {
        width: 100%;
        height: 0.25rem;
        margin: 0 0 0.25rem;
        background: imports.$sec-white;
        transition: background 0.25s ease;
    }

    .bar.last {
        margin: 0;
    }
}

.toggled {
    width: 1.5rem;
    transition: width 0.25s ease;

    .bar {
        background: imports.$sec-white;
    }
}

.scrolling {
    .bar {
        background: imports.$sec-black;
    }
}
