@use "./imports.scss";

* {
    box-sizing: border-box;
}

html,
body {
    font: 400 16px imports.$open-sans;
    background: imports.$prim-white;
    color: imports.$ter-black;
    margin: 0;
}

html,
body,
#root {
    /* For footer to be at bottom of page */
    width: 100%;
    height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    margin: 0.5rem auto;
    letter-spacing: 0.0625rem;
    line-height: 130%;
    color: imports.$prim-black;
    text-align: center;
}

p {
    line-height: 150%;
}

h1 {
    font-size: 3rem;
}
h2 {
    font-size: 2.5rem;
}
h3 {
    font-size: 2rem;
}
h4 {
    font-size: 1.75rem;
}
h5 {
    font-size: 1.5rem;
}
h6 {
    font-size: 1.25rem;
}

a {
    color: imports.$light-red;
    text-decoration: none;
    transition: color 0.25s ease;
    &:hover {
        color: imports.$not-as-light-red;
    }
    &:active {
        color: imports.$dark-red;
    }
}

.app {
    width: 100%;
    min-height: 100%;
}

.body {
    width: 100%;
}

.section {
    width: 100%;
    padding: 1rem 0;
}

.section.white {
    background: imports.$prim-white;
}

.section.gold {
    background: imports.$light-gold;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: imports.$prim-white;
    }
    p {
        color: imports.$sec-white;
    }
}

.content-wrap {
    width: 90%;
    max-width: 768px;
    margin: auto;

    &.centered {
        text-align: center;
    }
}

.sub-landing {
    width: 100%;
    padding: 3rem 0 1rem;
    background: imports.$light-gold;

    .title {
        color: imports.$prim-white;
        margin: 1rem auto;
        text-align: center;
        font-weight: 700;
        font-size: 1.75rem;
    }

    @media screen and (min-width: 768px) {
        .title {
            font-size: 2rem;
        }
    }
}

.footer {
    width: 100%;
    padding: 1rem 0.5rem;
    text-align: center;
    background: imports.$light-gold;

    .title {
        font-weight: 600;
        letter-spacing: 0.0625rem;
        color: imports.$prim-white;
    }

    .subtitle {
        color: imports.$sec-white;
        font-size: 0.75rem;
        margin: 0.5rem 0 1rem;
    }
}
