/* Primary accent */
$light-gold: #c0ba62;
$not-as-light-gold: #bbb556;
$dark-gold: #aba444;
$even-darker-gold: #a29c41;

/* Secondary accent */
$light-red: #d99aa2;
$not-as-light-red: #c28a91;
$dark-red: #c0525e;
$even-darker-red: #a34651;

/* For text color */
$prim-black: #303030;
$sec-black: #505050;
$ter-black: #707070;

/* greys */
$prim-grey: #c0c0c0;
$sec-grey: #a8a8a8;
$ter-grey: #909090;

/* For white background */
$prim-white: #f8f8f8;
$sec-white: #f0f0f0;
$ter-white: #e8e8e8;

$open-sans: "Open Sans", sans-serif;

@mixin styled-button($type: "gold") {
    display: inline-block;
    text-decoration: none;
    border-style: solid;
    border-width: 0.1875rem;
    letter-spacing: 0.0625rem;
    padding: 0.5rem;
    transition: color 0.25s ease, border-color 0.25s ease, background 0.25s ease;
    text-align: center;
    background: none;
    font-size: 1rem;
    cursor: pointer;

    @if $type == "gold" {
        color: $light-gold;
        border-color: $not-as-light-gold;
    } @else if $type == "white" {
        color: $ter-white;
        border-color: $sec-white;
    } @else if $type == "black" {
        color: $ter-black;
        border-color: $sec-black;
    }

    &:hover {
        color: $light-red;
        border-color: $not-as-light-red;
    }

    &:active {
        color: $sec-white;
        border-color: $dark-red;
        background: $dark-red;
    }

    &:disabled {
        opacity: 0.5;
        cursor: default;
        color: inherit;
        border-color: inherit;
        background: inherit;
    }
}
