@use "../../imports";

.update-form {
    display: block;
    width: 100%;
    margin: 1em auto 2em;

    .flex-wrap {
        display: flex;
        flex-direction: column;

        .update-form__subscribe,
        .update-form__textfield {
            width: 100%;
            font: 400 0.875rem imports.$open-sans;
            margin: 0.25rem 0;
        }

        .update-form__textfield {
            flex: 1;
            outline: none;
            padding: 0.75rem;
            border: none;
        }

        button.update-form__subscribe {
            display: block;
            flex: 0;
        }

        &.fadeout {
            display: none;
        }
    }

    .feedback {
        text-align: center;
        margin: 0.25rem auto 0;
        transition: opacity 0.5s ease, transform 0.5s ease;
        opacity: 0;
        transform: translateY(1rem);
        font-weight: 600;

        &.fadein {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &.gold {
        color: imports.$sec-white;

        .update-form__textfield {
            background: imports.$dark-gold;
            color: imports.$sec-white;
            transition: background 0.25s ease;

            &:focus {
                background: imports.$even-darker-gold;
            }
        }

        ::placeholder {
            color: imports.$light-gold;
        }

        .update-form__subscribe {
            @include imports.styled-button("white");
        }

        .feedback {
            color: imports.$ter-white;
        }
    }

    &.black {
        color: imports.$sec-black;

        .update-form__textfield {
            background: imports.$ter-white;
            color: imports.$ter-black;
            transition: background 0.25s ease;

            &:focus {
                background: #e0e0e0;
            }
        }

        ::placeholder {
            color: #b0b0b0;
        }

        .update-form__subscribe {
            @include imports.styled-button("black");
        }

        .feedback {
            color: imports.$ter-black;
        }
    }
}

@media screen and (min-width: 768px) {
    .update-form {
        .flex-wrap {
            display: flex;
            flex-direction: row;

            .update-form__textfield {
                margin-right: 0.25rem;
            }

            .update-form__subscribe {
                flex: 0;
                margin-left: 0.25rem;
                cursor: pointer;
            }
        }
    }
}
