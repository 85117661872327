@use "../../imports.scss";

.album {
    display: block;
    margin: 1rem auto;
    width: 90%;
    max-width: 1600px;

    .album__header {
        display: flex;
        flex-direction: column;

        .header__text {
            flex: 1;
            margin-bottom: 0.5rem;

            .album__title {
                text-transform: capitalize;
                letter-spacing: 0.0625rem;
                text-align: left;

                a {
                    text-decoration: underline;
                    color: imports.$sec-black;
                }
            }

            .album__date {
                font-size: 0.75rem;
                color: #808080;
                margin: 0.5rem auto 0;
            }
        }

        .header__operations {
            flex: 0;

            button {
                @include imports.styled-button("black");
                white-space: nowrap;
                font-size: 0.75rem;
            }
        }
    }

    .album__photos {
        border-top: solid 1px imports.$sec-grey;
        margin: 0.5rem auto 1rem;
        padding: 1rem 0;
        display: grid;
        grid-gap: 0.5rem;
        grid-template-columns: repeat(2, minmax(6rem, 16rem));
        animation: 0.5s ease-in photofadein;

        .photo {
            width: 100%;
            margin: 0;
            padding: 0;
            border: none;
            background: none;
            outline: none;
            cursor: pointer;

            &:hover {
                filter: brightness(0.5);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

@for $i from 0 to 9 {
    @media screen and (min-width: calc(512px + (128px * $i))) {
        .album .album__photos {
            grid-template-columns: repeat($i + 3, minmax(6rem, 16rem));
        }
    }
}

@media screen and (min-width: 768px) {
    .album .album__header {
        flex-direction: row;
    }
}
